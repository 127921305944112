const MESSAGE = {
  EN: {
    time_label: 'Time',
    count_label: 'Count'
  },
  TH: {
    time_label: 'เวลา',
    count_label: 'จำนวน'
  }
}
export default MESSAGE
