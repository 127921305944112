import React from 'react'
import PropTypes from 'prop-types'
import BarChartComponent from '../BarChartComponent/Loadable'
import SkeletonLoading from '../SkeletonLoading/Loadable'
import CardWrapper from '../CardWrapper/Loadable'

import ViolationCountGraphStyled from './styledComponent'

import MESSAGE from './message'

class ViolationCountGraph extends React.PureComponent {
  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getViolationCountGraph() {
    const graphComponent = (
      <BarChartComponent
        listBars={this.props.listGraphDetail}
        data={this.props.graphData}
        xLabel={this.getMessage('time_label')}
        yLabel={this.getMessage('count_label')}
      />
    )
    return (
      <CardWrapper cardTitle={this.props.title}>
        <div className="violation-graph-wrapper">
          <div className="chart-container">{this.props.graphData.length > 0 ? graphComponent : null}</div>
        </div>
      </CardWrapper>
    )
  }

  getContent() {
    const content = this.props.isLoading ? <SkeletonLoading /> : this.getViolationCountGraph()
    return content
  }

  render() {
    return <ViolationCountGraphStyled>{this.getContent()}</ViolationCountGraphStyled>
  }
}

ViolationCountGraph.defaultProps = {
  language: 'EN',
  isLoading: false
}

ViolationCountGraph.propTypes = {
  title: PropTypes.string,
  graphData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      cameraName: PropTypes.number // this field is dynamically change according to camera name
    })
  ),
  listGraphDetail: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  language: PropTypes.string
}

export default ViolationCountGraph
