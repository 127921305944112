import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 295px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  .violation-graph-wrapper {
    background: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`
